<template>
  <div>
    <van-nav-bar
        :title="title"
        left-text="返回"
        right-text="确认"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
    />
    <div>
      <van-search
          v-model="searchParamValue"
          show-action
          label="商品"
          placeholder="请输入商品关键词"
          @search="onSearch"
          @clear="onSearchClear"
      >
        <template #action>
          <div @click="showPopup" style="color: #1989fa">分类</div>
        </template>
      </van-search>
    </div>

    <van-sticky style="text-align: right">
      <van-button type="primary" size="small" square @click="showSelectedCandidateProduct">查看已选择</van-button>
    </van-sticky>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in dataList" :key="item.productAttrUnique" :name="item.productAttrUnique">
          <van-card
              tag="热卖"
              :price="(Number(item.price) * 100 / 100).toFixed(2)"
              :title="item.keyword"
              :thumb="item.image"
          >
            <template #desc>
              <van-notice-bar :color="item.stockWarn ? '#ee0a24' : '#1989fa'" background="#fafafa">
                {{ item.stockZNum}}{{item.zUnitName}}{{item.stockSNum}}{{item.sUnitName}}
              </van-notice-bar>
            </template>
            <template #tags>
              <van-button type="primary" size="small" style="width: 100px; height: 25px"
                          @click="onSelectedProduct(item)">选择
              </van-button>
            </template>
          </van-card>
        </div>
      </van-list>
    </van-pull-refresh>


    <van-popup v-model="showSelectedProducts" position="right" style="width: 80%">
      <div v-for="item in selectedProducts" :key="item.productId" :name="item.productId">
        <van-card
            :num="item.totalNum"
            tag="已选择"
            :price="(Number(item.totalPrice) * 100 / 100).toFixed(2)"
            :desc="item.sku"
            :title="item.storeName"
            :thumb="item.image"
        >
          <template #tags>
            <van-button type="primary" size="small" style="width: 100px; height: 25px" @click="editProduct(item)">修改
            </van-button>
          </template>
        </van-card>
      </div>
    </van-popup>


    <van-popup v-model="showEditProduct" style="width: 90%; max-height: 90%; padding-bottom: 10px">
      <van-card
          :desc="selectedProduct.storeInfo"
          :title="selectedProduct.storeName"
          :thumb="selectedProduct.image"
          :origin-price="selectedProduct.price"
      />
      <div v-for="item in selectedProduct.storeProductAttrValues" :key="item.unique" :name="item.unique">
        <div tabindex="0"
             class="van-cell van-cell--center van-cell--borderless van-contact-card van-contact-card--edit">
          <div class="van-cell__value van-cell__value--alone van-contact-card__value">
            <span>{{ item.sku }}</span>

            <span v-if="item.stockWarn" style="margin-left: 5px; color: #ee0a24">{{item.stockZNum}}{{item.zUnitName}}{{item.stockSNum}}{{item.sUnitName}}</span>
            <span v-if="!item.stockWarn" style="margin-left: 5px; color: #07c160">{{item.stockZNum}}{{item.zUnitName}}{{item.stockSNum}}{{item.sUnitName}}</span>
            <van-field v-model="item.price" left-icon="refund-o" @focus="clearPrice(item)"
                       size="small" rows="1" type="number" style="margin-bottom: 3px;"/>
            <van-stepper v-model.number="item.number"
                         step="1"
                         integer
                         input-width="40px"
                         button-size="40px"/>
          </div>
          <van-button icon="delete-o" size="small" type="info" @click="onDeleteProductAttr(item.productAttrUnique)">删除
          </van-button>
        </div>
      </div>

      <van-button type="primary" size="small" block style="width: 90%; margin: 5px auto 0;"
                  @click="submitEditProduct">
        确定
      </van-button>
    </van-popup>

    <itemSelectPopup ref="itemSelectPopup"></itemSelectPopup>
  </div>
</template>

<script>
import {
  Button,
  Cell, NavBar, Popup, Search, ShareSheet, Card, Field, Icon, PullRefresh, List, Stepper, Sticky, NoticeBar
} from 'vant';
import * as orderAdminService from "@/api/admin/order";
import itemSelectPopup from "@/components/itemSelectPopup";
import {mapState} from 'vuex'
import $store from "@/store";

export default {
  components: {
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Search.name]: Search,
    [NavBar.name]: NavBar,
    [ShareSheet.name]: ShareSheet,
    [Card.name]: Card,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Stepper.name]: Stepper,
    [Sticky.name]: Sticky,
    [NoticeBar.name]: NoticeBar,
    itemSelectPopup: itemSelectPopup,
  },

  data() {
    return {
      showSelectedProducts: false,
      showEditProduct: false,
      options: [],
      title: '选择商品',
      searchParamValue: '',
      searchParam: {},

      selectedProduct: {},
      selectedProductAttrs: [],
      selectedProducts: [],

      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
    };
  },
  watch: {
    cate: {
      handler(newVal) {
        if (newVal.cateId === undefined) {
          return
        }
        this.onSelectCate(newVal.cateId)
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState({
      cate: state => state.order.cate,
    }),
  },
  created() {
    this.searchParam.orderType = 2;
    if (this.$route.params.userId !== undefined && this.$route.params.userId !== 'undefined' && this.$route.params.userId !== 'null' && this.$route.params.userId !== null) {
      this.searchParam.accountUserId = this.$route.params.userId
    }
  },
  methods: {
    showSelectedCandidateProduct() {
      console.log(this.selectedProducts)
      this.showSelectedProducts = true
    },
    clearPrice(item) {
      item.price = ''
    },
    onSelectedProduct(product) {
      console.log(product)
      product.productId = product.id
      product.storeProductAttrValues.forEach((item) => {
        item.cateId = product.cateId
        item.storeName = product.storeName
        item.storeInfo = product.storeInfo
        item.productAttrUnique = item.unique
      })
      this.selectedProduct = product
      console.log(this.selectedProduct)
      this.showEditProduct = true
    },
    submitEditProduct() {
      let amount = 0;
      let number = 0;
      let skuArray = [];
      console.log(this.selectedProduct.storeProductAttrValues)

      if (this.selectedProduct.storeProductAttrValues.length === 0) {
        this.selectedProduct = {}
        this.onRefresh()
        return
      }
      this.selectedProduct.storeProductAttrValues.forEach((item, index) => {
        amount += item.price * item.number
        number += item.number;
        item.productAttrUnique = item.unique
        skuArray.push(item.sku);
      })
      this.selectedProduct.sku = skuArray.join(',')
      this.selectedProduct.totalNum = number
      this.selectedProduct.totalPrice = amount

      let selectedProduct = this.selectedProducts.filter(item => item.productId === this.selectedProduct.productId);
      if (selectedProduct === undefined || selectedProduct.length < 1) {
        this.selectedProducts.push(this.selectedProduct)
        this.showEditProduct = false
        this.selectedProduct = {}
        return;
      }
      this.selectedProduct.storeProductAttrValues.forEach(item => {
        let selectedProductAttr = selectedProduct[0].storeProductAttrValues.filter(t => t.unique === item.unique);
        if (selectedProductAttr === undefined || selectedProductAttr.length < 1) {
          selectedProduct[0].storeProductAttrValues.push(item)
          selectedProduct[0].sku = skuArray.join(',')
        }
      })

      amount = 0;
      number = 0;
      skuArray = [];
      selectedProduct[0].storeProductAttrValues.forEach((item, index) => {
        amount += item.price * item.number
        number += item.number;
        item.productAttrUnique = item.unique
        skuArray.push(item.sku);
      })
      selectedProduct[0].sku = skuArray.join(',')
      selectedProduct[0].totalNum = number
      selectedProduct[0].totalPrice = amount
      console.log(this.selectedProducts)
      this.showEditProduct = false
      this.selectedProduct = {}
      this.onRefresh()
    },
    onDeleteProductAttr(productAttrUnique) {
      for (let j = 0; j < this.selectedProduct.storeProductAttrValues.length; j++) {
        if (this.selectedProduct.storeProductAttrValues[j].productAttrUnique === productAttrUnique) {
          this.selectedProduct.storeProductAttrValues.splice(j, 1);
        }
      }
      for (let j = 0; j < this.selectedProducts.length; j++) {
        console.log(this.selectedProducts[j].storeProductAttrValues.length)
        if (this.selectedProducts[j].storeProductAttrValues === undefined || this.selectedProducts[j].storeProductAttrValues.length === 0) {
          this.selectedProducts.splice(j, 1);
        }
      }
    },
    editProduct(product) {
      this.selectedProduct = product
      this.showEditProduct = true
    },
    onSelectCate(cateId) {
      this.searchParamValue = '条件筛选'
      this.searchParam.sid = cateId
      this.onRefresh();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      $store.commit('SET_PRODUCTS', this.selectedProducts)
      this.selectedProducts = []
      this.selectedProduct = {}
      this.onRefresh()
      this.$router.push({path: $store.state.order.selectProductAfter.redirect});
    },
    onSearchClear() {
      this.searchParam.storeName = null
      this.searchParam.cateId = null
      this.onRefresh();
    },
    onSearch() {
      this.searchParam.storeName = this.searchParamValue;
      this.onRefresh();
    },
    showPopup() {
      this.$refs.itemSelectPopup.isPopupShow = true
    },
    onLoad() {
      console.log('onload')
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      this.searchParam.type = $store.state.order.selectProductAfter.type
      this.searchParam.userId = $store.state.order.selectProductAfter.userId
      let that = this
      orderAdminService.getAccountProducts(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          console.log(this.pages)
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }
          console.log(that.dataList)
        }
      });
    },
    onRefresh() {
      console.log('父亲刷新我')
      console.log(this.searchParam)
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      console.log(this.pages)
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
  }
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
